import React from "react";
import { useTranslation } from "react-i18next";

import "./PromoBlock.scss";
import { Link } from "react-scroll";

import HeroIllustration from "@assets/img/Hero-Illustration-PNG.png";
import BuisnessHeroIllustration from "@assets/img/buisness-hero-Illustration.png";

import { useGlobalState } from "@/GlobalStateContext";

import params from "../../params.json";

const projectCode = process.env.REACT_APP_PROJECT_CODE || "test";
const { API_URL: apiUrl } = params[projectCode];

function PromoBlock() {
  const { siteVersion } = useGlobalState();
  const { t: tSiteVersion } = useTranslation([`${siteVersion}`]);

  return (
    <div className="promo-block">
      <div className="promo-block-benefits">
        <h1>{tSiteVersion("promoBlock.title")} </h1>
        <p
          dangerouslySetInnerHTML={{
            __html: tSiteVersion("promoBlock.description"),
          }}
        />

        {siteVersion === "client" && (
          <div className="promo-block-limit-btn">
            <h2>{tSiteVersion("promoBlock.limit-btn.title")}</h2>
            <a
              href={`${apiUrl}/client/PartnersOrder/10`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="raspberry-button">
                {tSiteVersion("promoBlock.limit-btn.btn")}
              </button>
            </a>
          </div>
        )}

        {siteVersion !== "client" && (
          <Link to="feedback-form" smooth>
            <button>{tSiteVersion("promoBlock.button.text")}</button>
          </Link>
        )}
      </div>
      {siteVersion === "client" ? (
        <img src={HeroIllustration} alt="Hero-Illustration" />
      ) : (
        <img src={BuisnessHeroIllustration} alt="Hero-Illustration" />
      )}
    </div>
  );
}

export default PromoBlock;
